<template>
    <footer>
      <div class="container mx-auto mt-20 mb-20 grid md:grid-cols-4 gap-12 md:gap-20">
        <div class="col-span-1">
          <NuxtLink to="/">
          <NuxtPicture v-if="data.data.attributes.footer.logo.data.attributes.hash"
                       :width="data.data.attributes.footer.logo.data.attributes.width"
                       :height="data.data.attributes.footer.logo.data.attributes.height"
                       loading="lazy"
                       sizes="56px"
                       :src="data.data.attributes.footer.logo.data.attributes.hash"
                       :alt="data.data.attributes.footer.logo.data.attributes.alternativeText || ''"
                       :imgAttrs="{
                          class:'w-8 h-8',
                          
                       }"
          />
          </NuxtLink>
        </div>
        <div class="col-span-3 grid grid-cols-2 md:grid-cols-3 gap-5 max-md:gap-y-12 md:gap-16">
          <div>
            <h3 class="font-medium mb-4">Solution</h3>
            <ul class="text-sm grid gap-3">
              <li v-for="item in data.data.attributes.footer.Solution">
                <NuxtLink :to="item.href">
                  {{ item.title }}
                </NuxtLink>
              </li>
            </ul>
          </div>
          <div>
            <h3 class="font-medium mb-4">Company</h3>
            <ul class="text-sm grid gap-3">
              <li v-for="item in data.data.attributes.footer.company">
                <NuxtLink :to="item.href">
                  {{ item.title }}
                </NuxtLink>
              </li>
            </ul>
          </div>
          <div>
            <h3 class="font-medium mb-4">Contact</h3>
            <ul class="text-sm grid gap-6">
              <li v-for="item in data.data.attributes.footer.contactPhone" class="flex gap-3">
                <div>{{ item.flag }}</div>
                <div class="flex flex-col gap-1">
                  <a class="font-medium hover:underline" :href="'tel:' + item.phoneNumberLink.replace(/\s/g, '')">{{ item.phoneNumberTitle }}</a>
                  <p v-if="item.addressLine1">{{ item.addressLine1 }}</p>
                  <p v-if="item.addressLine2">{{ item.addressLine2 }}</p>
                </div>
                
              </li>
              <li v-for="item in data.data.attributes.footer.contactEmail">
                <a class="border-b border-b-black" :href="'mailto:' + item.email">{{ item.email }}</a>
              </li>
              <li v-for="item in data.data.attributes.footer.contactLink">
                <NuxtLink :to="item.href">
                  {{ item.title }}
                </NuxtLink>
              </li>
            </ul>
          </div>
          <div>
            <h3 class="font-medium mb-4">Use</h3>
            <ul class="text-sm grid gap-3">
              <li v-for="item in data.data.attributes.footer.use">
                <NuxtLink :to="item.href">
                  {{ item.title }}
                </NuxtLink>
              </li>
            </ul>
          </div>
          <div>
            <h3 class="font-medium mb-4">Compare</h3>
            <ul class="text-sm grid gap-3">
              <li v-for="item in data.data.attributes.footer.compare">
                <NuxtLink :to="item.href">
                  {{ item.title }}
                </NuxtLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container mx-auto mt-20 mb-20 border-t border-grey pt-6 flex max-md:flex-col justify-between items-start gap-8 md:gap-2">
        <div v-if="data.data.attributes.certifications" class="flex items-center gap-4">
          <div v-for="certificate in certificates.data">
            <div class="h-[56px] w-[56px] relative">
              <NuxtPicture v-if="certificate?.attributes.logo.data.attributes.hash"
                           
                           loading="lazy"
                           :height="certificate?.attributes.logo.data.attributes.height"
                           :width="certificate?.attributes.logo.data.attributes.width"
                           sizes="56px"
                           :src="certificate?.attributes.logo.data.attributes.hash"
                           :alt="certificate?.attributes.logo.data.attributes.alternativeText || ''"
                           :imgAttrs="{
                          class:'w-auto h-full'
                       }"
              />
              <NuxtLink v-if="certificate?.attributes.link" class="absolute inset-0 h-full w-full" :to="certificate?.attributes.link"/>
            </div>
          </div>
        </div>
        <div class="max-md:order-last">
          <p class="text-black/60 text-sm">{{  data.data.attributes.copyright  }}</p>
        </div>
        <div>
          <ul class="text-black/60 text-sm grid gap-3">
            <li v-for="item in data.data.attributes.links">
              <NuxtLink class="-m-1 p-1" :to="item.href">
                  {{ item.title }}
                </NuxtLink>
            </li>
          </ul>
        </div>
      </div>
    </footer>
</template>

<script setup lang="ts">
  import Button from "../components/components/Button.vue";
  import MarkdownRenderer from '../components/components/MarkDown.vue';
  import {useFetch} from "nuxt/app";
  import Icon from "../components/components/Icon.vue";

  const runtimeConfig = useRuntimeConfig();
  const { data, pending } = useFetch(runtimeConfig.public.STRAPI_API_URL + '/footer?pLevel', {
	 headers: {
      "Strapi-Response-Format": "v4",
    }
});
  const { data: certificates, pending: pendingCert } = useFetch(runtimeConfig.public.STRAPI_API_URL + '/certificates?pLevel', {
	 headers: {
      "Strapi-Response-Format": "v4",
    }
}) || {};
</script>