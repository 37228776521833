<template>
    <Header/>
    <section class="relative">
      <div class="relative container md:mx-auto pb-36 pt-24 md:pt-44 relative z-10 grid md:grid-cols-2 gap-12 md:gap-20">
        <div class="max-md:hidden absolute right-10 -top-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="120" height="160" viewBox="0 0 120 160" fill="none">
            <path d="M40.0003 30.4762C56.8319 30.4762 70.4766 16.8315 70.4766 0" stroke="#26DDFD" stroke-width="19.0476"/>
            <path d="M70.4762 79.9999C70.4762 63.1684 56.8316 49.5237 40 49.5237" stroke="#26DDFD" stroke-width="19.0476"/>
            <path d="M9.52378 80.0001C9.52378 96.8316 23.1684 110.476 40 110.476" stroke="#26DDFD" stroke-width="19.0476"/>
            <path d="M70.4762 160C70.4762 143.168 56.8316 129.524 40 129.524" stroke="#26DDFD" stroke-width="19.0476"/>
            <path d="M120 89.5238C103.168 89.5238 89.5234 103.168 89.5234 120" stroke="#26DDFD" stroke-width="19.0476"/>
          </svg>
        </div>
        <div>
          <div class="grid gap-3">
            <h1 class="text-3xl md:text-5xl font-medium tracking-tighter text-balance"> {{ data.data.attributes.title }} </h1>
            <h3 class="text-xl font-medium tracking-tighter text-balance"> {{ data.data.attributes.lead }} </h3>
          </div>
          <div v-if="data.data.attributes.content" class="prose text-black mt-6 md:mt-12">
            <MarkdownRenderer :markdown="data.data.attributes.content" />
          </div>
          <div class="mt-6 flex max-md:flex-col gap-2 md:items-center">
            <Button
                v-for="button in data.data.attributes.buttons"
                :title="button.title"
                :link="button.href"
                :variant="button.type"
                :size="button.size"
                :arrow="button.arrow"
            />
          </div>
          <div v-if="data.data.attributes.quote" class="max-w-md prose-sm text-black mt-8 md:mt-16">
            <MarkdownRenderer :markdown="data.data.attributes.quote" />
          </div>
        </div>
        <div class="flex justify-end">
          <div class="w-full max-w-[524px] self-end">
            <NuxtPicture v-if="data.data.attributes.image.data?.attributes.hash"
            :width="data.data.attributes.image.data?.attributes.width"
                :height="data.data.attributes.image.data?.attributes.height"
                         loading="lazy"
                         :src="data.data.attributes.image.data?.attributes.hash"
                         :imgAttrs="{
                          class:'w-full',
                          
                       }"
            />
          </div>
        </div>
      </div>
    </section>
    <Footer/>
</template>

<script setup lang="ts">
  import Button from "/components/components/Button.vue";
  import MarkdownRenderer from "/components/components/MarkDown.vue";
  import {useFetch} from "nuxt/app";

  const runtimeConfig = useRuntimeConfig();
  const { data, pending } = await useFetch(runtimeConfig.public.STRAPI_API_URL + '/error-page?pLevel', {
	 headers: {
      "Strapi-Response-Format": "v4",
    }
});


  const seoData = data?.value?.data?.attributes?.seo || {};
const { data: globalSeoData } = await useFetch(runtimeConfig.public.STRAPI_API_URL + '/global-seo-setting?pLevel', {
	 headers: {
      "Strapi-Response-Format": "v4",
    }
});
const seoDataNoIndex = seoData.noIndex ?? false;
const seoDataTitle = seoData.pageTitle;
const seoDataDescription = seoData.shortDescription;
const seoDataCanonicalUrl = seoData.canonicalURL
const seoDataOgTitle = seoData.ogTitle ?? seoDataTitle
const seoDataOgDescription = seoData.ogDescription  ?? seoDataDescription
const seoDataOgImage = seoData?.image?.data ?? globalSeoData?.value?.data?.attributes?.ogImage.data;
const seoDataOgImageUrl = seoDataOgImage.attributes.url;



useSeoMeta({
  title: () => seoDataTitle,
  ogTitle: () => seoDataOgTitle,
  description: () => seoDataDescription,
  ogDescription:  () => seoDataOgDescription,
  ogImage: () => seoDataOgImageUrl,
})

useHead({
  meta: [
    seoDataNoIndex != false ? { name: 'robots', content: 'noindex, follow' } : {},
  ],
  script: [
    seoData.Schema != null ? { type: 'application/ld+json', innerHTML: seoData.Schema } : {},
    globalSeoData.value.data.attributes.globalSchema != {} ? { type: 'application/ld+json', innerHTML: globalSeoData.value.data.attributes.globalSchema } : {},
  ]
})
</script>